/* You can add global styles to this file, and also import other style files */

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "../../../node_modules/primeflex/primeflex.css";


.pointer {
  cursor: pointer;
}

.text-decoration-none {
  text-decoration: none;
}

// .p-menuitem-link-active {
//   background-color: rgb(158, 158, 158);
// }

.progress-spinner {
  position: fixed;
  z-index: 3000;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(216, 216, 216, 0.53);
}

.tsep-input > input {
  color: #495057;
  border: 1px solid #ced4da ;
  border-radius: 6px ;
  font-size: 1rem ;
  padding: 0.75rem;
}

.tsep-error > input {
  border: 1px solid red;
}


////////////////////////////////////////////////////////////////////////
////////////////////////NEW STYLING////////////////////////////////////
//////////////////////////////////////////////////////////////////////




:root {

  //------COLORING---//

  --app-error-text: #eb445a;
  --primary-text-color: #EFEFEF;
  --secondary-text-color: #446c92;

  --primary-bg-color: #181C23;
  --secondary-bg-color: #f3f3f3;

  --primary-button-color: #181C23;
  --primary-button-color-hover: #181C23cb;

  --secondary-button-color:#ffff;

  //------SIZING---//

  --sm-font-size:1rem;
  --md-font-size:2rem;
  --lg-font-size:2.5rem;
  --icon-size-lg: 2rem;
  --icon-size-md: 1.5rem;
}




// ----------------- GLOBAL ------------------ //


html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  // background-color: var(--secondary-bg-color);
}

a{
  text-decoration: none;
}

aim-footer {
  margin-top: auto;
}


h1 {
  font-size: var(--lg-font-size);
}

.w-100 {
  width: 100%;
}

// ----------------- CONTAINER ------------------ //


.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-container {
  display: block;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 1rem;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.primary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

// ----------------- FONT SIZE ------------------ //

.sm-font-size {
  font-size: var(--sm-font-size);
}

.md-font-size {
  font-size: var(--md-font-size);
}

.lg-font-size {
  font-size: var(--lg-font-size);
}


// ----------------- TEXT ------------------ //

.primary-text-color {
  color: var(--primary-text-color);
  text-decoration: none;
}


.secondary-text-color {
  color: var(--secondary-text-color);

}

.primary-text {
  font-size: var(--sm-font-size);
  color: var(--primary-text-color);
  text-decoration: none;
}

.primary-text:hover {
  color: var(--secondary-bg-color);
}


// ----------------- BACKGROUND ------------------ //

.primary-bg-color {
  background-color: var(--primary-bg-color);
}

.secondary-bg-color{
  background-color: var(--secondary-bg-color);

}


// ----------------- BUTTON ------------------ //

.primary-button-color {
  padding: 10px 20px;
  font-size:  var(--sm-font-size);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  background-color: var(--primary-button-color);
  color: var(--primary-text-color);
}

.primary-button-color:hover {
  background-color: var(--primary-button-color-hover);
}

.primary-button {
  color:var(--primary-text-color);
  background-color: var(--primary-button-color);
  border: 1px solid var(--primary-button-color);
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  margin: 1px 1px;
}

.p-selectbutton .p-button.p-highlight {
  background: var(--primary-button-color);
  border-color: var(--primary-button-color);
  color: var(--primary-text-color);
}


///////////////////////////////////////////////////////////////////////////
//////////////////////// PRODUCT CONTAINER ////////////////////////////////


.product-container .p-button.p-button-icon-only.p-button-rounded {
  background: var(--primary-button-color);
  border-color: var(--primary-button-color);
  color: var(--primary-text-color);
}

.product-container .p-button-rounded.p-button.p-component {
  background: var(--primary-button-color);
  border-color: var(--primary-button-color);
  color: var(--primary-text-color);

}
.product-container button.p-ripple.p-element.p-button.p-component {
  color: var(--primary-text-color);
  background: var(--primary-button-color);
  border-color: var(--primary-button-color);
}


.product-container button.p-element.p-button-icon-only.p-inputnumber-button.p-inputnumber-button-up.p-button.p-component{
  background: var(--primary-button-color);
  border-color: var(--primary-button-color);
  color: var(--primary-text-color);
}

.product-container .p-sidebar-header {
  background-color: var(--secondary-bg-color);
  padding: 0.5rem;
  margin: 0 0 0.5rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-container .p-sidebar-footer {
  background-color: var(--secondary-bg-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

// CARD INPUT BUTTON
.p-inputtex.tp-component.p-element.p-inputnumber-input.p-filled{
  background-color: var(--secondary-bg-color);

}
button.p-element.p-button-icon-only.p-inputnumber-button.p-inputnumber-button-up.p-button.p-component{
  background: var(--primary-button-color);
  border-color: var(--primary-button-color);
  color: var(--primary-text-color);
}
button.p-element.p-button-icon-only.p-inputnumber-button.p-inputnumber-button-down.p-button.p-component{
  background: var(--primary-button-color);
  border-color: var(--primary-button-color);
  color: var(--primary-text-color);
}

// DIALOG BUTTON
button.p-element.p-confirm-dialog-reject {
  background: var(--secondary-button-color);
  color: var(--secondary-text-color);
  border: 1px solid var(--primary-button-color);
}
button.p-element.p-confirm-dialog-accept{

  background: var(--primary-button-color);
  color: var(--primary-text-color);
  border: 1px solid var(--primary-button-color);
}


.p-dataview-emptymessage {
  text-align: center;
}
//-------------- PI ----------------------//

.pi {
  color: var(--primary-text-color);
}

.pi-search {
  color: #EFEFEF;
}


///////////////////////////////////////////////////////////////////////////
//////////////////////// checkout //////////////////////////////////////////

.p-accordion .p-accordion-content {
  background: var(--secondary-bg-color);

}

.checkout-main-container .p-button {
  background: var(--primary-bg-color);
  border: 1px solid var(--primary-bg-color);

}
///////////////////////////////////////////////////////////////////////////
//////////////////////// DIALOG //////////////////////////////////////////


.p-dialog .p-dialog-header {
  background: var(--secondary-bg-color);
  padding: 0.5rem;
}

.p-dialog .p-dialog-content {
  padding: 2rem 1.5em;
}
.p-dialog .p-dialog-footer {
  padding: 1rem;
}

///////////////////////////////////////////////////////////////////////////
//////////////////////// PRODUCTS STYLING //////////////////////////////////

.product-container #desktop-filter {
  background: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 0.5rem;
  margin: 0 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-container #desktop-filter a {
  text-decoration: none;
}

.product-container p-dataview.p-element.flex-grow-1.z-1.ng-star-inserted {
    border-radius: 10px;
}

.product-container .p-dataview .p-dataview-header {
  background: var(--secondary-bg-color);
  padding: 1rem 1rem;
  border-radius: 10px 10px 0px 0px;
}

.grid.grid-nogutter.ng-star-inserted {
  background: var(--secondary-bg-color);
}
.product-container .p-paginator.p-component.ng-star-inserted {
  background: var(--secondary-bg-color) ;

}


.product-container a {
  color: var(--primary-bg-color);
}


main.flex.gap-3.flex-wrap.justify-content-center.ng-star-inserted.main-container {
  display: block;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 1rem;
  background-color: var(--secondary-bg-color) !important;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


///////////////////////////////////////////////////////////////////////////
//////////////////////// PRODUCT STYLING //////////////////////////////////
.product-info-contianer button.p-ripple.p-element.p-button.p-component {
  background: var(--primary-button-color);
  border-color: var(--primary-button-color);
  color: var(--primary-text-color);
}


.product-info-contianer .product-info  {
  max-width: 400px;
  background: #fff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}
ngx-aim-category-filter div {
  background: var(--secondary-bg-color);
  padding: 4px;
}
ngx-aim-category-filter div:not(:only-child) {
  background: #fff;
  padding: 4px;
}
.product-info h1, .product-info h2 {
  color: var(--secondary-text-color);
}

///////////////////////////////////////////////////////////////////////////
//////////////////////// CART STYLING //////////////////////////////////

.cart-container .p-dataview .p-dataview-content {
  background: var(--secondary-bg-color);
}

///////////////////////////////////////////////////////////////////////////
//////////////////////// account overlay header //////////////////////////
.p-overlaypanel .p-overlaypanel-close {
  background: var(--secondary-bg-color);
  color:var(--secondary-text-color)
}


///////////////////////////////////////////////////////////////////////////
//////////////////////// order ////////////////////////////////


.p-datatable .p-sortable-column.p-highlight {
  color:var(--secondary-text-color)
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color:var(--secondary-text-color)
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color:var(--secondary-text-color)
}


///////////////////////////////////////////////////////////////////////////
//////////////////////// create-account-successful ////////////////////////////////


.account-verification-component .p-card-header img {
  width: 30%;
}

.account-verification-component .p-card-header.ng-star-inserted {
  justify-content: center;
  display: flex;
}

.account-verification-component .p-card.p-component {
  width: 50%;
  padding: 0.5rem 0;
}


///////////////////////////////////////////////////////////////////////////
//////////////////////// create-account-successful ////////////////////////

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: var((--primary-bg-color));
  color: var(--primary-text-color);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: var((--primary-bg-color));
  color: var(--primary-text-color);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: var(--secondary-bg-color);
}


///////////////////////////////////////////////////////////////////////////
//////////////////////// MEDIA QUERY ///////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////




@media (max-width: 768px) {

  h1 {
    font-size: var(--md-font-size);
  }

  .content {
    padding: 10px;
  }
  :root {
    --icon-size-lg: 1.5rem;
  }
  .account-verification-component .p-card-header img {
    width: 20%;
  }
  .account-verification-component .p-card.p-component {
    width: 95%;
  }

  .product-container .p-dropdown.p-component.p-inputwrapper {
    width: 100%;
  }

  ///// MARGIN ON FILTER IN PRODUCT CONTAINER ////
  .product-container .flex.gap-1 {
    margin: 0.5rem 0;
  }

  ///// CHANGE THE POSITION OF LAYOUT SELECTION IN PRODUCTS CONTAINER ////
  .product-container p-dataviewlayoutoptions.p-element {
      justify-content: flex-end;
      display: flex;

  }
}

// work around for
// https://github.com/primefaces/primeng/issues/8447
.p-carousel-item {
  flex: 1 0 100%;
}

.quill-no-margin p {
  margin: 0;
}
